import { usePage } from '@inertiajs/react';
import { Box, Button, Checkbox, Container, Group, rem, Stack, Text, TextInput, Title } from '@mantine/core';
import { isEmail } from '@mantine/form';

import { FormProvider } from '@/components/form-context';
import useInertiaForm from '@/components/useInertiaForm.jsx';

export default function Newsletter({ errors, props }) {
  const pageProps = usePage().props;

  const form = useInertiaForm(
    {
      initialValues: {
        email: '',
        terms: '',
      },
      validate: {
        email: isEmail('Invalid email'),
        terms: value => (value ? null : 'This field is required'),
      },
    },
    pageProps?.errors,
    route('site.newsletter'),
    'Confirmation email sent! Please confirm your subscription.',
    false,
    {
      preserveState: true,
      successCallback: () => {
        form.reset();
      },
    },
  );

  return (
    <Box bg="gfdark.9" id="trust">
      <Container py={70} {...props}>
        <FormProvider form={form}>
          <form onSubmit={e => e.preventDefault()}>
            <Stack align="center">
              <Title align={'center'} lh={1} fw={500} fz={rem(48)} lts={rem(-3)} c={'white'}>
                Join Our Newsletter!
              </Title>
              <Text c="gfdark.5" fz={rem(18)} fw={'lighter'} align={'center'}>
                Join our network, stay connected!
              </Text>

              <TextInput
                type={'email'}
                label="E-MAIL"
                placeholder="Enter your e-mail"
                size="md"
                mt={'md'}
                w={{ base: '100%', xs: 400 }}
                classNames={{ input: 'input-large', label: 'input-large-label' }}
                {...form.getInputProps('email')}
              />

              <Checkbox
                label={'I agree to the terms of service'}
                c={'gfdark.4'}
                mt={'xs'}
                {...form.getInputProps('terms', { type: 'checkbox' })}
              />

              <Group mt={'xl'}>
                <Button type="submit" onClick={form.handleSubmit} size="lg" variant="outline-gf" c="white">
                  JOIN
                </Button>
              </Group>
            </Stack>
          </form>
        </FormProvider>
      </Container>
    </Box>
  );
}
